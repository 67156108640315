<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Manage Drivers</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-driver-modal><i class="flaticon2-plus"></i> Add New</b-button>
            </div>
          </div>

          <div class="card-body body-fluid">
            <b-table responsive striped hover :items="drivers" show-empty :fields="fields">
              <template #cell(actions)="row">
                <b-button
                  @click="deleteDriver(row.item.id)"
                  class="ml-2"
                  variant="danger"><i class="flaticon2-cross"></i> Delete</b-button>
              </template>
               <template #cell(name)="row">
                  <a class="a" @click="ViewDriver(row.item.id)">{{row.item.first_name}} {{row.item.last_name}}</a>
                </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <b-modal
      id="add-driver-modal"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addDriver"
      title="Add Driver"
      size="lg"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
            <div class="col-md-12">
                <label>Personal Details</label>
            </div>
          <div class="col-md-4">
            <b-form-group label="">
              <b-form-input placeholder="Account No" v-model="form.account_no"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-2">
            <b-form-group label="">
                <b-form-input placeholder="Title" v-model="form.title"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="">
              <b-form-input placeholder="First name" v-model="form.first_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="">
              <b-form-input placeholder="Last name" v-model="form.last_name"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Email">
              <b-form-input placeholder="name@domain.com" v-model="form.email"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Phone">
              <b-form-input placeholder="+91 888 470 8921" v-model="form.phone"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Password">
              <b-form-input type="password" v-model="form.password"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group label="Confirm Password">
              <b-form-input type="password" v-model="form.confirm_password"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
             <b-form-select
                :options="branches"
                value-field="id"
                v-model="form.branch_id"
                text-field="name">
              </b-form-select>
          </div>
        </div>
      </form>
    </b-modal>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      drivers: [],
      isLoading: false,
      errors: [],
      fields: [
        {
          key: "unique_id",
          label: "Account No",
        },
        { 
          key: "name",
          label: "Name",
          formatter: (value, key, item) => {
            return item.first_name + " " + item.last_name;
          },
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "phone",
          label: "Phone",
        },
        {
          key: "branch",
          label: "Branch",
        },
        {
          key: "is_active",
          label: "Status",
           formatter: (value) => {
                if(value){
                    return "Active";
                }else{
                    return "Inactive";
                }
          },
        },
        { key: "actions", label: "Actions" },
      ],
      form: {
        account_no: null,
        title: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        confirm_password: null,
        branch_id: null,
        id: null,
      },
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getAllDrivers();
    this.getAllBranches();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Manage Drivers" },
    ]);
  },
  methods: {
    checkErrors() {
      if (this.form.account_no && this.form.first_name && this.form.last_name && this.form.email && this.form.phone && this.form.password &&
        this.form.confirm_password && this.form.branch_id && this.form.password == this.form.confirm_password
      ) {
        return true;
      }
      this.errors = [];
      if (!this.form.account_no) {
        this.errors.push("Account No is required.");
      }
      if (!this.form.first_name) {
        this.errors.push("First Name is required.");
      }
      if (!this.form.last_name) {
        this.errors.push("Last Name is required.");
      }
      if (!this.form.email) {
        this.errors.push("Email is required.");
      }
      if (!this.form.phone) {
        this.errors.push("Phone is required.");
      }
      if (!this.form.password) {
        this.errors.push("Password is required.");
      }
      if (!this.form.confirm_password) {
        this.errors.push("Confirm Password is required.");
      }
      if (!this.form.branch_id) {
        this.errors.push("Branch is required.");
      }
      if (this.form.password != this.form.confirm_password) {
        this.errors.push("Both password should match");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    ViewDriver(id) {
      this.$router.push({ name: "view-driver", params: { id: id } });
    },
    resetModal() {
      this.form.account_no = null;
      this.form.title = null;
      this.form.first_name = null;
      this.form.last_name = null;
      this.form.email = null;
      this.form.phone = null;
      this.form.password = null;
      this.form.confirm_password = null;
      this.form.branch_id = this.branches.length ? this.branches[0].id : null;
    },
    addDriver(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("addDriver", this.form)
          .then(() => {
            this.getAllDrivers();
            this.$nextTick(() => {
              this.$bvModal.hide("add-driver-modal");
              this.makeToastVariant(
                "success",
                "Driver added successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    getAllDrivers() {
      this.isLoading = true;
      ApiService.get("getAllDrivers")
        .then(({ data }) => {
          this.drivers = data.drivers;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getAllBranches() {
      this.isLoading = true;
      ApiService.get("branch")
        .then(({ data }) => {
          this.branches = data;
          this.form.branch_id = this.branches.length ? this.branches[0].id : null;
        })
        .catch(() => {
        });
    },
    deleteDriver(id) {
      this.isLoading = true;
      ApiService.delete("deleteDriver", id)
        .then(() => {
          this.getAllDrivers();
          this.makeToastVariant(
            "warning",
            "Driver deleted successfully",
            "Success"
          );
        })
        .catch(() => {
        });
    },
  },
};
</script>
